<form [formGroup]="form" class="add-region-form-wrapper">
    <mat-toolbar matDialogTitle>
        <h2>{{isEditMode ? 'Edit Region' : 'Add Region'}}</h2>
        <button class="md-button md-icon-button" matDialogClose aria-label="close dialog">
            <i class="fa fa-times" aria-hidden="true"></i>
        </button>
    </mat-toolbar>

    <mat-dialog-content>
        <p>
            Please open a state below to select the location for the new region.
        </p>
        <mat-form-field class="name-form-field" appearance="outline" floatLabel="always" [hideRequiredMarker]="true">
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" maxlength="250" placeholder="Name" />
        </mat-form-field>
        <mat-form-field appearance="outline" floatLabel="always" class="form-field-description text-area">
            <mat-label>Description</mat-label>
            <textarea rows="5" matInput placeholder="Description" formControlName="description"
                maxlength="250"></textarea>
            <mat-hint>{{form.get('description').value.length}} / 250</mat-hint>
        </mat-form-field>
        <div *ngIf="isEditMode" class="toggle">
            <mat-slide-toggle formControlName='active' labelPosition="before">Enabled</mat-slide-toggle>
        </div>
    </mat-dialog-content>

    
    <mat-dialog-actions>
        <a matDialogClose>
            <span>Cancel</span>
        </a>
        <button (click)="confirm()" [disabled]="!form.valid" class="md-button confirmButton">
            CONFIRM
        </button>
    </mat-dialog-actions>
</form>