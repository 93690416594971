import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { catchError, Observable, of, Subscription, tap } from 'rxjs';
import { ResponsiveUIService } from 'src/app/services/responsiveUI.service';
import { UserService } from '../../services/user.service';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastService } from 'src/app/services/toast.service';
import { CookieService } from 'ngx-cookie';
import { Router } from '@angular/router';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { memoize } from 'src/app/utils';

@Component({
  selector: 'app-select-job-titles',
  templateUrl: './selectJobTitles.component.html',
  styleUrls: ['./selectJobTitles.component.scss']
})
export class SelectJobTitlesComponent implements OnInit, OnDestroy {
  jobs: Job[] = [];
  currUser: any;
  subs = new Subscription();
  @ViewChild('otherInput', {read: ElementRef}) otherInput = null;

  constructor(
    private userService: UserService,
    private toastService: ToastService,
    private cookieService:CookieService,
    private router: Router,
    public responsiveUIService: ResponsiveUIService) {
  }

  ngOnInit(): void {
    this.getCurrentUser();
    this.getJobTitles();
  }

  getCurrentUser() {
    this.currUser = this.userService.getCurrentUser().then( data => {
      this.currUser = data;
    });
  }

  getJobTitles() {
    this.subs.add((this.userService.getJobTitles(this.currUser.username).pipe(tap((jobs:any[]) => this.jobs = jobs)).subscribe()));
  }

  onJobSelectionChange(evt: MatCheckboxChange, jobTitleKey: number) {
    this.jobs = this.jobs.map(j => j.jobTitleKey === jobTitleKey ? ({...j, selected: !j.selected}) : j);
  }

  onOtherJobChange(jobValue: string, jobTitleKey: number) {
    this.jobs = this.jobs.map(j => j.jobTitleKey === jobTitleKey ? ({...j, otherJobTitleDesc: jobValue}) : j);
  }

  updateUser() {
    this.userService.updateJobTitlesForUser(this.currUser.username, this.jobs)
    .pipe(
      tap(() => {
        this.toastService.showToast('User updated!');
        let userCookie:any = this.cookieService.getObject('user');
        userCookie.needsJobTitle = false;
        this.cookieService.putObject('user', userCookie);
        this.router.navigate(['home']);
      }), 
      catchError(e => {
        this.toastService.showToast(e.error.developerMessage, 0);
        return of(null)
      })
    ).subscribe();
   
  }

  isJobSelected() {
    const onlyOtherSelected = this.jobs.find(j => j.selected)?.jobTitleDesc === 'Other';
    return this.jobs.some(j => j.selected === true) && !(onlyOtherSelected && !this.otherInput?.nativeElement?.value?.trim())
  }


  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}


export interface Job {
  jobTitleDesc: string,
  selected: boolean,
  otherJobTitleDesc: string,
  jobTitleKey: number
}