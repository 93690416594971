import { NgModule } from "@angular/core";
import { SharedModule } from '../../shared.module';
import { ChildDetailsComponent } from '../child-details/child-details.component';
import { StudentDetailsHistoricalRawViewComponent } from "../student-details-historical-raw-view/student-details-historical-raw-view.component";
import  { AddEditStudentToListDialogComponent } from '../add-edit-student-to-list-dialog/add-edit-student-to-list-dialog.component'

@NgModule({
    declarations: [ChildDetailsComponent, StudentDetailsHistoricalRawViewComponent, AddEditStudentToListDialogComponent],
    imports: [SharedModule],
    providers: [],
    exports: [ChildDetailsComponent, StudentDetailsHistoricalRawViewComponent, AddEditStudentToListDialogComponent]
})
export class StudentDetailsModule { }