
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { catchError, of, Subscription, tap } from 'rxjs';
import { ResponsiveUIService } from 'src/app/services/responsiveUI.service';
import { SavedListService } from 'src/app/services/savedlist.service';
import { ToastService } from 'src/app/services/toast.service';
import { DialogData } from '../student-details/student-details.component';
import { RequiredIfCheckedValidator } from '../../validators/required-if-checked';

// export interface DialogData {
//     checkedLists: any[],
//     newCheckedLists: any[],
//     uncheckedLists: any[],
//     newListName: string
//   }

@Component({
    selector: 'app-add-edit-student-to-list-dialog.component',
    styleUrls: ['./add-edit-student-to-list-dialog.component.scss'],
    templateUrl: './add-edit-student-to-list-dialog.component.html'
})

export class AddEditStudentToListDialogComponent implements OnDestroy {
  savedLists = [];
  checkedListsTracker: DialogData;
  checkedLists = [];
  uncheckedLists = [];
  subs = new Subscription();

  form: FormGroup = this.fb.group({
      newListName: new FormControl('', [Validators.maxLength(60)]),
      newListNameCheck: new FormControl(false)
  }, {
    validators: [RequiredIfCheckedValidator()],
  })
  
  constructor(
    public dialogRef: MatDialogRef<AddEditStudentToListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {checkedListTracker: DialogData, isSaved: boolean},
    private savedListService: SavedListService,
    private toastService: ToastService,
    private fb: FormBuilder,
    public responsiveUIService: ResponsiveUIService,
  ) {
    this.getSavedListsNames();
    this.checkedListsTracker = {...data.checkedListTracker};
    this.checkedListsTracker.newListName = '';
  }

  setCheckedLists(saveListKey: number) {
    let checkedIndex = this.checkedListsTracker.checkedLists.indexOf(saveListKey);
    let uncheckedIndex = this.checkedListsTracker.uncheckedLists.indexOf(saveListKey);
    let newCheckedIndex = this.checkedListsTracker.newCheckedLists.indexOf(saveListKey);
    if (checkedIndex === -1) {
        this.checkedListsTracker.checkedLists.push(saveListKey);
        this.checkedListsTracker.newCheckedLists.push(saveListKey);
        if (uncheckedIndex > -1) {
        this.checkedListsTracker.uncheckedLists.splice(uncheckedIndex, 1);
        }
    } else {
        this.checkedListsTracker.checkedLists.splice(checkedIndex, 1);
        if (newCheckedIndex > -1) {
        this.checkedListsTracker.newCheckedLists.splice(newCheckedIndex, 1);
        }
        this.checkedListsTracker.uncheckedLists.push(saveListKey);
    }
  }

  
  getSavedListsNames() {
    this.savedListService.getSaveListsNames().pipe(
        // currently api returns My Default List as the last item in the array. this is a work around, as we need to display this as the second item on the UI.
        tap(listNames =>  this.savedLists = [listNames.at(-1), ...listNames.slice(0, -1)]),
        catchError((err) => {
            this.toastService.showToast(err, 0);
            return of(null)
        })
    ).subscribe();
  }

  
  selectAll() {
    for (let list of this.savedLists) {
        let checkedIndex = this.checkedListsTracker.checkedLists.indexOf(list.saveListKey);
        if (checkedIndex === -1) {
          this.checkedListsTracker.checkedLists.push(list.saveListKey);
          this.checkedListsTracker.newCheckedLists.push(list.saveListKey);
        }
      }
      this.checkedListsTracker.uncheckedLists = [];
  }

  selectNone() {
    for (let key of this.checkedListsTracker.checkedLists) {
        this.checkedListsTracker.uncheckedLists.push(key);
      }
      this.checkedListsTracker.checkedLists = [];
      this.checkedListsTracker.newCheckedLists = [];
  }

  onSubmit(): void {
    console.log(this.checkedListsTracker)
    this.dialogRef.close(this.checkedListsTracker);
  }

  ngOnDestroy(): void {
      this.subs.unsubscribe();
  }
}