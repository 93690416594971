import { DialogRef } from '@angular/cdk/dialog';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { catchError, finalize, of, Subscription, tap } from 'rxjs';
import { StateService } from 'src/app/services/state.service';
import { ToastService } from 'src/app/services/toast.service';

@Component ({
    selector:'app-add-edit-region-dialog',
    templateUrl: './add-edit-region-dialog.component.html',
    styleUrls: ['./add-edit-region-dialog.component.scss']     
})

export class AddEditRegionDialogComponent implements OnInit, OnDestroy {
    form: FormGroup;
    subscription = new Subscription();
    isEditMode: boolean = false;
    constructor(
        private dialogRef: DialogRef,  
        private fb: FormBuilder, 
        private stateService: StateService, 
        private toastService: ToastService, @Inject(MAT_DIALOG_DATA) private data?: {name: string, description: string, key: number, stateKey: string, active: boolean}) {

    }

    ngOnInit(): void {
        this.initForm();
        if (this.data) {
            this.setFormValues();
            this.isEditMode = true;
        }
    }

    initForm() {
        this.form = this.fb.group({
            name: ['', Validators.required],
            description: ['']
        })
    }

    setFormValues() {
        this.form.get('name').setValue(this.data.name);
        this.form.get('description').setValue(this.data.description);
        this.form.addControl('active', new FormControl(this.data.active));
    }

    confirm() {
        if (this.isEditMode) {
            this.updatedRegion()
        } else {
            this.addRegion();
        }
    }

    updatedRegion() {
        this.subscription.add(this.stateService.updateRegion({...this.form.value, key: this.data.key, stateKey: this.data.stateKey})
        .pipe(tap(() => {
            this.toastService.showToast('Region Updated!');
        }), catchError(error => {
            this.toastService.showToast(error.error.developerMessage, 0);
            return of(null);
        }), finalize(() => {
            this.dialogRef.close(true);
        })
        ).subscribe())
    }

    addRegion() {
        this.subscription.add(this.stateService.createRegion(this.form.value)
        .pipe(tap(() => {
            this.toastService.showToast('Region Added!');
        }), catchError(error => {
            this.toastService.showToast(error.error.developerMessage, 0);
            return of(null);
        }), finalize(() => {
            this.dialogRef.close(true);
        })
        ).subscribe())
    }

      ngOnDestroy(): void {
          this.subscription.unsubscribe();
      }
}