<div class="header">
    <p>{{data.isSaved ? 'Edit/Remove Student Record From List' : 'Add Student
        Record'}}
    </p>
    <button class="md-icon-button" mat-dialog-close aria-label="close dialog">
        <i class="fa fa-times" aria-hidden="true"></i>
    </button>
</div>
<div class="wrapper">
    <p *ngIf="!data.isSaved">
        Select the list(s) to which you would like to add this student record. To create a new list, select “Create
        New List” and enter a name for the new list.
    </p>
    <p *ngIf="data.isSaved">Select the lists to which you would like to add the
        student record.
        De-select the lists that you do not want the student record to be in.
        De-select all lists to remove the student record from all of your lists.
        Use the “Select All” link to select all lists. Use the “Select None” link to de-select all lists.
        To create a new list, select “Create New List” and enter a name for the new list.
    </p>
    <div class="select-options">
        <p>Select</p>
        <a tabindex="0" (click)="selectAll()" (keypress)="selectAll()">All</a>
        <p>|</p>
        <a tabindex="0" (click)="selectNone()" (keypress)="selectNone()">None</a>
    </div>
    <div class="bordered-section" [formGroup]="form">
        <mat-checkbox formControlName="newListNameCheck"> </mat-checkbox>
        <mat-form-field floatLabel="always" appearance="fill">
            <input matInput formControlName="newListName" placeholder="Create New List" />
            <mat-error *ngIf="form.get('newListName')?.errors?.maxlength">
                List names must be 60 characters or less.
            </mat-error>
            <mat-error >
                List name already exists. Enter a different name.
            </mat-error>
            <mat-error *ngIf="form?.errors?.newListNameRequired">
                Enter a name for the new list.
            </mat-error>
        </mat-form-field>


        <div *ngFor="let list of savedLists">
            <mat-checkbox (change)="setCheckedLists(list.saveListKey)" (keypress)="setCheckedLists(list.saveListKey)">
                {{list.saveListName}}
            </mat-checkbox>
        </div>
    </div>

    <div class="action-buttons">
        <a tabindex="0" class="resetButton" mat-dialog-close>
            Cancel
        </a>
        <button class="confirmButton md-button border" (click)="onSubmit()" (keypress)="onSubmit()">Submit</button>
    </div>
</div>