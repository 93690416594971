<div class="job-title-container">
  <h2>Update Job Title</h2>
  <div class="help-text">
    MSIX now requires Job Titles for all users. Select at least one of the updated Job Titles
    from the checkboxes below. If Other is selected, ensure that the text field contains an entry.
  </div>
  <form class="form">
    <p>Job Title *</p>
    <hr class="thinHr">
    <div class="job-list-container" *ngIf="jobs">
      <div class="job-column">
        <ng-container *ngFor="let job of jobs | slice:0: 4">
          <mat-checkbox  (change)="onJobSelectionChange($event, job.jobTitleKey)" [checked]="job.selected">
            <p>{{job.jobTitleDesc}}</p>
          </mat-checkbox>
        </ng-container>
      </div>
      <div class="job-column">
        <ng-container *ngFor="let job of jobs | slice:4:7">
          <mat-checkbox (change)="onJobSelectionChange($event, job.jobTitleKey)" [checked]="job.selected">
            <p>{{job.jobTitleDesc}}</p>
          </mat-checkbox>
        </ng-container>
      </div>
      <div class="job-column">
        <ng-container *ngFor="let job of jobs | slice:7">
          <div class="other-field">
            <mat-checkbox (change)="onJobSelectionChange($event, job.jobTitleKey)" [checked]="job.selected">
              <p>{{job.jobTitleDesc}}</p>
            </mat-checkbox>
            <ng-container *ngIf="job.jobTitleDesc === 'Other'">
              <mat-form-field appearance="outline">
                <input matInput type="text" [disabled]="!job.selected" #otherInput maxlength="100"
                [value]="job.otherJobTitleDesc"
                (change)="onOtherJobChange(otherInput.value, job.jobTitleKey)" />
              </mat-form-field>
            </ng-container>
          </div>
      </ng-container>
      </div>
    </div>

    <button (click)="updateUser()" class="md-button submit-button" [disabled]="!isJobSelected()">
      SUBMIT
    </button>
  </form>
</div>